<template>
    <validation-observer :ref="observerId" #default="{ invalid }" slim>
        <b-form
            inline
            class="range-params d-flex flex-column align-items-end flex-md-row justify-content-end"
            @submit.prevent
        >
            <p class="my-auto mr-0 mr-md-1">{{ $t('ui.period') }}</p>

            <div
                v-if="isDatePickerVisible(selectedPeriodId)"
                class="position-relative d-md-flex mt-1 mt-md-0 mr-md-1"
            >
                <!-- datepicker -->
                <validation-provider
                    #default="{ failed }"
                    name="date-range"
                    rules="required"
                    slim
                >
                    <flat-pickr
                        v-model="datePicker"
                        :config="datePickerConfig"
                        class="form-control flat-picker bg-transparent border shadow-none cursor-pointer datepicker-custom"
                        :class="{
                            'rounded bg-danger bg-lighten-2 text-white': failed,
                        }"
                        placeholder="YYYY-MM-DD"
                        required
                    />
                </validation-provider>

                <!-- Scale selector -->
                <validation-provider
                    v-if="showScaleSelector"
                    #default="{ failed }"
                    name="scale"
                    rules="required"
                    slim
                >
                    <v-select
                        v-model="selectedScaleValue"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="scaleOptions"
                        class="select-size-small mt-1 mt-md-0 ml-md-1"
                        :class="{
                            'rounded bg-danger bg-lighten-2 text-white': failed,
                        }"
                        label="name"
                        :reduce="(scaleOption) => scaleOption.value"
                        required
                        :clearable="false"
                        :searchable="false"
                        width="100"
                    />
                </validation-provider>
            </div>

            <validation-provider
                #default="{ failed }"
                name="period-option"
                rules="required"
                slim
            >
                <v-select
                    v-model="selectedPeriodId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="periodOptions"
                    class="select-size-big mt-1 mt-md-0 mr-md-1"
                    :class="{
                        'rounded bg-danger bg-lighten-2 text-white': failed,
                    }"
                    label="name"
                    :reduce="(periodOption) => periodOption.id"
                    required
                    :clearable="false"
                    :searchable="false"
                    width="100"
                />
            </validation-provider>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                type="submit"
                :disabled="invalid || isLoading"
                class="button-size-wide ml-auto mt-1 ml-md-0 mt-md-0"
                @click="validateForm"
            >
                <span v-if="isLoading" class="d-flex align-items-end">
                    <b-spinner variant="light" small class="mr-50" />

                    <span>
                        {{ $t('ui.loading') }}
                    </span>
                </span>

                <span v-else>
                    {{ $t('ui.show') }}
                </span>
            </b-button>
        </b-form>
    </validation-observer>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */

import i18n from '@/libs/i18n/index';
import { BForm, BSpinner, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';
import '@core/scss/vue/libs/vue-select.scss';
import flatPickr from 'vue-flatpickr-component';
import '@core/scss/vue/libs/vue-flatpicker.scss';
// import 'flatpickr/dist/plugins/monthSelect/style.css';
import { watch, ref, reactive } from '@vue/composition-api';
import { getDateOffset, getMaxDate } from '@/utils/formatter';

export default {
    components: {
        BForm,
        BButton,
        BSpinner,

        ValidationObserver,
        ValidationProvider,

        vSelect,
        flatPickr,
    },

    directives: {
        Ripple,
    },

    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },

        sendInitialEvent: {
            type: Boolean,
            default: true,
        },

        initialPeriodOptionId: {
            type: Number,
            default: 2,
        },

        showScaleSelector: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            // Validation rules
            required,

            observerId: 'rangeParamsForm',
        };
    },

    methods: {
        validateForm() {
            this.$refs[this.observerId].validate().then(() => {
                this.submitDateParams();
            });
        },
    },

    setup(props, { emit }) {
        const { sendInitialEvent, initialPeriodOptionId } = props;
        const i18nReactive = reactive(i18n);
        const getLocalizedScaleOptions = () => {
            return [
                {
                    value: 'daily',
                    name: i18nReactive.t('ui.byHours'),
                },
                {
                    value: 'monthly',
                    name: i18nReactive.t('ui.byDays'),
                },
            ];
        };
        const getLocalizedPeriodOptions = () => {
            return [
                {
                    id: 1,
                    from: getDateOffset({ days: 0 }),
                    to: getDateOffset({ days: 0 }),
                    scale: 'daily',
                    name: i18nReactive.t('ui.today'),
                    isCustom: false,
                },
                {
                    id: 2,
                    from: getDateOffset({ days: 1 }),
                    to: getDateOffset({ days: 1 }),
                    scale: 'daily',
                    name: i18nReactive.t('ui.yesterday'),
                    isCustom: false,
                },
                {
                    id: 3,
                    from: getDateOffset({ days: 7 }),
                    to: getDateOffset({ days: 1 }),
                    scale: 'monthly',
                    name: i18nReactive.t('ui.last7Days'),
                    isCustom: false,
                },
                {
                    id: 4,
                    from: getDateOffset({ days: 31 }),
                    to: getDateOffset({ days: 1 }),
                    scale: 'monthly',
                    name: i18nReactive.t('ui.last30Days'),
                    isCustom: false,
                },
                {
                    id: 6,
                    from: getDateOffset({ days: 7 }),
                    to: getDateOffset({ days: 1 }),
                    scale: 'monthly',
                    name: i18nReactive.t('ui.arbitrary'),
                    isCustom: true,
                },
            ];
        };
        const scaleOptions = ref(getLocalizedScaleOptions());
        const periodOptions = ref(getLocalizedPeriodOptions());
        const getPeriodOptionById = (id) => {
            return (
                periodOptions.value.find(
                    (periodOption) => periodOption.id === id
                ) || {}
            );
        };
        const isDatePickerVisible = (id) => getPeriodOptionById(id).isCustom;
        const selectedPeriodId = ref(null);
        const conjunction = ' to ';
        const datePickerConfig = {
            mode: 'range',
            conjunction,
            maxDate: getMaxDate(),
            altInput: true,
            altFormat: 'j M Y',
            dateFormat: 'Y-m-d',
        };
        const datePicker = ref('');
        const selectedScaleValue = ref('');

        const updatePeriod = (newSelectedPeriodId) => {
            const newSelectedPeriodOption = getPeriodOptionById(
                newSelectedPeriodId
            );

            datePicker.value = `${newSelectedPeriodOption.from}${conjunction}${newSelectedPeriodOption.to}`;
            selectedScaleValue.value = newSelectedPeriodOption.scale;
        };

        const submitDateParams = () => {
            const dateRange = datePicker.value.split(conjunction);
            const dateParams = {
                from: `${dateRange[0]} 00:00:00`,
                to: `${dateRange[1] ?? dateRange[0]} 23:59:59`,
                scale: selectedScaleValue.value,
            };

            emit('update', dateParams);
        };

        const setInitialState = (initialSelectedPeriodId = 1) => {
            selectedPeriodId.value = initialSelectedPeriodId;

            updatePeriod(initialSelectedPeriodId);

            if (sendInitialEvent) {
                submitDateParams();
            }
        };

        setInitialState(initialPeriodOptionId);

        watch(selectedPeriodId, updatePeriod);

        watch(
            () => i18nReactive.locale,
            () => {
                scaleOptions.value = getLocalizedScaleOptions();
                periodOptions.value = getLocalizedPeriodOptions();
            }
        );

        return {
            // Data
            datePickerConfig,

            // Reactive data
            scaleOptions,
            selectedScaleValue,
            periodOptions,
            selectedPeriodId,
            datePicker,

            // Methods
            isDatePickerVisible,
            submitDateParams,
        };
    },
};
</script>

<style lang="scss">
.range-params {
    .cursor-pointer {
        cursor: pointer;
    }

    .datepicker-custom {
        min-width: 230px !important;
        text-align: center;
        border-color: rgb(216, 214, 222) !important;
    }

    .select-size-small {
        min-width: 150px;
    }

    .select-size-big {
        min-width: 210px;
    }

    .button-size-wide {
        min-width: 130px;
    }
}
</style>
