import store from '@/store';
import i18n from '@/libs/i18n/index';

import useHandleError from '@/utils/useHandleError';

import { reactive, ref, set, watch } from '@vue/composition-api';

export default function useTrials() {
    const { showErrorMessage } = useHandleError();
    const i18nReactive = reactive(i18n);

    const trialsData = reactive({
        count: null,
        canceled: {
            total: null,
            before_payments: null,
            after_payments: null,
        },
        records: [],
    });
    const isLoading = ref(true);

    const getLocalizedLabels = () => {
        return [
            {
                key: 'email',
                sortable: true,
            },
            {
                key: 'subscribed',
                sortable: true,
                label: i18nReactive.t('trials.subscriptionDate'),
            },
            {
                key: 'canceled',
                sortable: true,
                label: i18nReactive.t('trials.subscriptionCancelDate'),
            },
        ];
    };

    const tableColumns = ref(getLocalizedLabels());
    const refetchTableColumnLables = () => {
        tableColumns.value = getLocalizedLabels();
    };

    const refreshArray = (initialArr, newArr) => {
        const initialArrLength = initialArr.length;

        initialArr.splice(0, initialArrLength, ...newArr);
    };

    const refreshObject = (initialObj, newObj) => {
        Object.keys(newObj).forEach((key) => {
            if (Array.isArray(newObj[key])) {
                refreshArray(initialObj[key], newObj[key]);

                return;
            }

            set(initialObj, key, newObj[key]);
        });
    };

    const fetchTrialsStatistics = ({ from, to }) => {
        isLoading.value = true;

        return store
            .dispatch('app-trials/fetchTrialsStatistics', {
                locale: i18nReactive.locale,
                from,
                to,
            })
            .then((responce) => {
                refreshObject(trialsData, responce.data);
            })
            .catch(showErrorMessage)
            .catch(() => {})
            .finally(() => {
                isLoading.value = false;
            });
    };

    watch(
        () => i18nReactive.locale,
        () => refetchTableColumnLables()
    );

    return {
        // Reactive data
        trialsData,
        isLoading,
        tableColumns,

        // Methods
        fetchTrialsStatistics,
    };
}
