<template>
    <b-card no-body>
        <b-card-header
            class="d-flex justify-content-between flex-column flex-md-row"
        >
            <b-card-title class="mr-auto">{{
                $t('trials.title')
            }}</b-card-title>

            <!-- datepicker -->
            <form-date-range-presets
                class="ml-auto"
                :is-loading="isLoading"
                :show-scale-selector="false"
                @update="fetchTrialsStatistics"
            />
        </b-card-header>

        <b-card-body class="position-relative">
            <!-- Spinner -->
            <div
                v-if="isLoading"
                :style="`min-height: ${height}px;`"
                class="position-relative"
            >
                <div class="centered">
                    <b-spinner variant="primary" />
                </div>
            </div>

            <div v-else>
                <!-- Overall statistics -->
                <div>
                    <!-- Total -->
                    <b-card-sub-title class="mt-50">
                        <span>{{ $t('trials.total') + ' - ' }}</span>

                        <span class="text-body font-weight-bold">
                            {{ getFormattedThousands(trialsData.count) }}
                        </span>
                    </b-card-sub-title>

                    <!-- Canceled total -->
                    <b-card-sub-title class="mt-50">
                        <span>{{ $t('trials.canceled') + ' - ' }}</span>

                        <span class="text-body font-weight-bold">
                            {{
                                getFormattedThousands(trialsData.canceled.total)
                            }}
                        </span>
                    </b-card-sub-title>

                    <!-- Canceled before payments -->
                    <b-card-sub-title class="mt-50">
                        <span>{{
                            $t('trials.canceledBeforePayments') + ' - '
                        }}</span>

                        <span class="text-body font-weight-bold">
                            {{
                                getFormattedThousands(
                                    trialsData.canceled.before_payments
                                )
                            }}
                        </span>

                        <span class="text-danger">
                            {{
                                getPercentString(
                                    trialsData.canceled.before_payments,
                                    trialsData.canceled.total
                                )
                            }}
                        </span>
                    </b-card-sub-title>

                    <!-- Canceled after payments -->
                    <b-card-sub-title class="mt-50">
                        <span>{{
                            $t('trials.canceledAfterPayments') + ' - '
                        }}</span>

                        <span class="text-body font-weight-bold">
                            {{
                                getFormattedThousands(
                                    trialsData.canceled.after_payments
                                )
                            }}
                        </span>

                        <span class="text-danger">
                            {{
                                getPercentString(
                                    trialsData.canceled.after_payments,
                                    trialsData.canceled.total
                                )
                            }}
                        </span>
                    </b-card-sub-title>
                </div>

                <div class="mt-2">
                    <b-table
                        class="mt-1"
                        :items="trialsData.records"
                        :fields="tableColumns"
                        fixed
                        show-empty
                        :empty-text="$t('ui.searchNotFound')"
                    >
                    </b-table>
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BSpinner,
    BTable,
} from 'bootstrap-vue';
import {
    getFormattedDateRange,
    getFormattedThousands,
} from '@/utils/formatter';
import Ripple from 'vue-ripple-directive';
import FormDateRangePresets from '../statistics-components/statistics-form/FormDateRangePresets.vue';
import useTrials from './useTrials';

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BSpinner,
        BTable,

        FormDateRangePresets,
    },

    directives: {
        Ripple,
    },

    data() {
        return {
            height: 400,
        };
    },

    methods: {
        getPercentString(current, total) {
            if (current === null || current === undefined) return null;
            if (total === null || total === undefined) return null;

            const percent = (current / total) * 100;
            const roundedPercent = Math.round(percent * 100) / 100;

            return ` - ${roundedPercent}%`;
        },
    },

    setup() {
        const {
            trialsData,
            isLoading,
            tableColumns,
            fetchTrialsStatistics,
        } = useTrials();

        return {
            trialsData,
            isLoading,
            tableColumns,

            // Methods
            fetchTrialsStatistics,

            // Formatter
            getFormattedDateRange,
            getFormattedThousands,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
